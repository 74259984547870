.gpt3__header {
    display: flex;
    flex-direction: row;
}

.gpt3__header-content {
    flex: 1;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
    margin-top :5%
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 40px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.gpt3__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;

    display: flex;
    flex-direction: row;
}

.gpt3__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #FF4820;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}

button {
    font-size: 100%;
    overflow: visible;
    margin: 0;
    vertical-align: middle;
    width: auto;
  }
  .btn-submit-requests {
    background: #7F7FD5 !important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5) !important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5) !important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75) !important;
    border: none;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    color: #333 !important;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.09em;
    line-height: 25px;
    padding: 10px 15px;
    justify-content: space-between;
  }
  .btn-reset {
    background: #2980B9;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #FFFFFF, #6DD5FA, #2980B9);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #FFFFFF, #6DD5FA, #2980B9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    border: none;
    border-bottom: solid 1px #333;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    color: #333;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.09em;
    line-height: 25px;
    margin-left: 50px;
    padding: 10px 15px;
    justify-content: space-between;
  }

  .btn-submit-requests:hover{
    cursor: pointer;
    transition: all 1s ease-in-out 0s;
    -moz-transition: all 1s ease-in-out 0s;
    -webkit-transition: all 1s ease-in-out 0s;
    -o-transition: all 1s ease-in-out 0s;
    opacity: 1;
    transition-duration: 1s;
  }

  .btn-reset:hover{
    cursor: pointer;
  }


  .container{  padding-top: 20px;
    padding-bottom: 20px;}
  body{
    background-color: #ffffff;
  }
  
  .background-img{
    background-image: url("../../assets/background.svg" );
    height: 400px;
    width: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 5% auto;
    padding:20px;
    border: 1px solid #2a3cad;
    border-radius: 4px;
    box-shadow: 0px 0px 5px #2a3cad;
    position: relative;
  }
  
  .box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    background: #111845a6;
    box-sizing: border-box;
    overflow: hidden;
    box-shadow: 0 20px 50px rgb(23, 32, 90);
    border: 2px solid #2a3cad;
    color: white;
    padding: 20px;
  }
  
  .box:before{
    content: '';
    position:absolute;
    top:0;
    left:-100%;
    width:100%;
    height:100%;
    background: rgba(255,255,255,0.1);
    transition:0.5s;
    pointer-events: none;
  }
  
  .box:hover:before{
    left:-50%;
    transform: skewX(-5deg);
  }
  
  
  .box .content{
 
   
    border:1px solid #f0a591;
   
    display:flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0 5px 10px rgba(9,0,0,0.5);
    
  }
  
  .box span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
    
  }
  
  .box span:nth-child(1)
  {
    transform:rotate(0deg);
  }
  
  .box span:nth-child(2)
  {
    transform:rotate(90deg);
  }
  
  .box span:nth-child(3)
  {
    transform:rotate(180deg);
  }
  
  .box span:nth-child(4)
  {
    transform:rotate(270deg);
  }
  
  .box span:before
  {
    content: '';
    position: absolute;
    width:100%;
    height: 2px;
    background: #50dfdb;
    animation: animate 4s linear infinite;
  }
  
  @keyframes animate {
    0% {
    transform:scaleX(0);
    transform-origin: left;
    }
    50%
    {
      transform:scaleX(1);
    transform-origin: left;
    }
    50.1%
    {
      transform:scaleX(1);
    transform-origin: right;
      
    }
    
    100%
    {
      transform:scaleX(0);
    transform-origin: right;
      
    }
    
    
  }

  .social_icons{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-top:50px;
    grid-auto-flow: column; 
    

  }




  @media (min-width: 700px) {
    .card-wrapper {
        grid-template-columns: 1fr 1fr;
    }
}

.textdec{
  display:flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 48px;
  line-height: 75px;
  letter-spacing: -0.04em;
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right:800px;
}