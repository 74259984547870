@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");

.card {
  background-color: transparent;
  max-width: 330px;
  color:white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: .5px .5px .5px .5px #ecdcdc;

}
.card .banner {
 background-color: rgb(231, 99, 99);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 7rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  z-index: 1;
  position: relative;
}
.card .banner png {
  background-color: rgb(209, 172, 172);
  width: 4rem;
  height: 4rem;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
  border-radius: 50%;
  transform: translateY(50%);
  transition: transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
  border-color: black;
}

.card h2.name {
  text-align: center;
  padding: 0 2rem 0.5rem;
  margin: 0;
  color:white;
  text-shadow: 2px 2px 4px #000000;
}
.card .title {
  color: #ebdcdc;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 2rem 1.2rem;
  text-shadow: 2px 2px 4px #000000;
}

.card .desc {
  text-align: justify;
  padding: 0 2rem 2rem;
  order: 100;
  white-space: pre-line;
  text-shadow: 2px 2px 4px #000000;
}


  .image--cover {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-top:-80px;
    object-fit: cover;
    object-position: center;
    border-color: blue;
    z-index: 2;
    position: relative;
    padding:19px;
    transform: translate(95px,5px);
    margin-right: 300px;
    
  }

  .carddataview{
    display:flex;
    flex-direction:row; 
    justify-content:space-around;
  }



  .card:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transform: scale(1.1);
    transition: 1s ease-in;
    transition-property: font-size;
    transition-duration: 1s ;
    transition-delay: 2s;
    
  }

  
 
