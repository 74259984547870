.heading-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  margin-top: 3rem;
  color: white;
  font-weight: 500;
  font-size: 2rem;
  text-align: center;
}

.heading-container span {
  display: inline-block;
  margin: 0 auto;
  height: 5px;
  background: var(--primary-color);
  width: 30px;
  border-radius: 20px;
}

@media screen and (min-width: 600px) {
  .heading {
    font-size: 3rem;
  }
}
