.timeline1{
    width: 60%;
    margin-top:100px; 
    color:white;
}

.css-1768lqf-OutlineWrapper{
    background: #040c18 !important;
    height: 30% !important;
}


.mainapp{
    display: grid;
    place-items: center;
}

