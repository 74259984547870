
.pdfApp{
    width: 100%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .pdfWrapper{
    display: flex;
    align-items: center; 
    justify-content: center;
    overflow-x: auto;
  }
  
  .footer{
    display: flex;
    justify-content: space-between;
    height: 50px;

  }